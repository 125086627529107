import React, { useEffect } from 'react';
import { TypeAnimation } from 'react-type-animation';
import IntroductionPhoto from '../../assets/img/about-me-photo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { pageFunction } from '../../plugins/script';

function Introduction() {
  const quickConnect = [
    { id: 1, link: 'https://github.com/Kieutrongloc', isTarget: true, icon: faGithub },
    { id: 2, link: 'https://www.linkedin.com/in/loc-kieu-trong-12aa4b216', isTarget: true, icon: faLinkedin },
    { id: 3, link: 'https://www.facebook.com/kieutrongloc', isTarget: true, icon: faFacebook },
  ];

  const contactMeHandle = () => {
    pageFunction.handleScroll('contact')
  };

  const handleAnimationOnScroll = () => {
    const itemList = document.querySelectorAll('#greeting, #job-title, #introduction-content #messages, #introduction-content #contact-button, #introduction-content #quick-connect , #introduction-image');
    const windowHeight = window.innerHeight;
    const threshold = 140;

    itemList.forEach(item => {
      const itemRect = item.getBoundingClientRect();

      if (itemRect.top + threshold < windowHeight) {
        item.classList.add('slide-in');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleAnimationOnScroll);
    handleAnimationOnScroll();

    return () => {
      window.removeEventListener('scroll', handleAnimationOnScroll);
    };
  }, []);

  return (
    <div id="introduction">
      <section id="introduction-container">
        <div id="introduction-content">

          <div id="greeting">
            <FontAwesomeIcon icon={faChevronRight} />
            <TypeAnimation
              sequence={[
                'Hello there!',
                2000,
                'I am Trong Loc,',
                2000,
                'Welcome you here!',
                2000,
              ]}
              wrapper="span"
              speed={30}
              style={{ fontSize: '60px', display: 'inline-block', fontWeight: 'bolder' }}
              repeat={Infinity} //number
            />
          </div>

          <div id='job-title'>
            <h1>Web Developer</h1>
          </div>

          <div id='messages'>
            <p>
              Confucius did say something which really inspires me to embark on my journey in the IT field: <i>"Choose a job you love and you'll never have to work a day in your life"</i>.
              Since a little boy, my fascination with technology has been intensive, everything related to technology always made me feel so excited.<br></br><br></br>

              Coding was my true calling, where I could channel my unwavering enthusiasm. 
              Now, I stand prepared to shape remarkable creations fueled by my passion and motivation. 
              This drive has transformed into my mission, propelling me to venture beyond my safe-zone and go further...
            </p>
          </div>

          <div id='contact-button'>
              <div id='button' onClick={contactMeHandle}>
                <p>Contact me</p>
              </div>
              <div id='button-background'></div>
          </div>

          <div id='quick-connect'>
            {quickConnect.map((item) => (
              <a key={item.id} href={item.link} target={item.isTarget ? '_blank' : null} rel="noreferrer">
                <div>
                  <FontAwesomeIcon icon={item.icon} />
                </div>
              </a>
            ))}
          </div>
          
        </div>

        <div id="introduction-image">
          <div id='my-photo'>
            <img src={IntroductionPhoto} alt="my avatar page main" />
          </div>
          <div id='photo-background'></div>
        </div>

      </section>
    </div>
  );
}

export default Introduction;
