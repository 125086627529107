import React, {  useEffect, useRef, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume, faEnvelope, faX, faChevronRight, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import CallIcon from '../assets/img/call-icon.png';
import EmailIcon from '../assets/img/email-icon.png';
import MessengerIcon from '../assets/img/messenger-icon.png';
import LinkedInIcon from '../assets/img/linkedin-icon.png';
import AiAssistantIcon from '../assets/img/ai-assistant.png';
import UserIcon from '../assets/img/user-default-icon.png';
import { apiService } from '../api/apiService';

function ContactPopup() {

    const btnExpansionRef = useRef();
    const carouselRef = useRef(null);
    const closeBtnRef = useRef(null);
    const contactWrapperRef = useRef(null);
    const conversationWrapperRef = useRef(null);
    const conversationExpansionRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [promptInput, setPromptInput] = useState('');
    const [currentPrompt, setCurrentPrompt] = useState('');
    const [currentResponse, setCurrentResponse] = useState('');
    const [displayedText, setDisplayedText] = useState('');
    const [isAnswerComplete, setIsAnswerComplete] = useState(false);
    const [isRenderComplete, setIsRenderComplete] = useState(false);
    const previousResponseRef = useRef(null);
    const responseElRef = useRef(null);
    let [isScrolledToBottom, setIsScrolledToBottom] = useState(true);

    const contactCarouselItems = [
        {id: 1, title: 'Contact', icon: faPhoneVolume, interval: 3000, class: 'phone-call-btn'},
        {id: 2, icon: faEnvelope, interval: 500},
        {id: 3, icon: faFacebookMessenger, interval: 500},
        {id: 4, icon: faLinkedin, interval: 500},
    ];

    const expansionItems = [
        {id: 1, title: 'Phone', img: CallIcon, href: 'tel:+84 (0) 243 678 77 88', isTarget: false, alt: 'phone call'},
        {id: 2, title: 'Email', img: EmailIcon, href: 'mailto:kieutrongloc1298@gmail.com', isTarget: true, alt: 'email'},
        {id: 3, title: 'Messenger', img: MessengerIcon, href: 'https://www.messenger.com/t/100004668341209', isTarget: true, alt: 'messenger'},
        {id: 4, title: 'LinkedIn', img: LinkedInIcon, href: 'https://www.linkedin.com/in/lucas-bao-12aa4b216/', isTarget: true, alt: 'linkedin'},
    ];

    const [questions, setQuestions] = useState([
        {id: 1, asked: false, question: "Hãy cho tôi biết thêm về Lộc?"},
        {id: 2, asked: false, question: "What can you do?"},
        {id: 3, asked: false, question: "Tell me about technical skills of Loc?"},
        {id: 4, asked: false, question: "What is Loc's strength?"},
        {id: 5, asked: false, question: "Sự thật thú vị về Lộc?"},
        {id: 7, asked: false, question: "Tell me about a fun fact of Loc?"},
        {id: 8, asked: false, question: "What is Loc's favourite quote?"},
        {id: 9, asked: false, question: "What does Loc often do in his free time?"},
    ]);

    const toggleContact = () => {
        if (btnExpansionRef.current.style.display === "block") {
            // document.removeEventListener('click', clickOutsideHandle)
            btnExpansionRef.current.classList.add("hide");
            setTimeout(() => {
                btnExpansionRef.current.style.display = "none";
            }, 300);
            closeBtnRef.current.style.display = "none"
            carouselRef.current.element.style.display = "block";
        } else {
            document.addEventListener('click', clickOutsideHandle)
            btnExpansionRef.current.classList.remove("hide");
            btnExpansionRef.current.style.display = "block";
            carouselRef.current.element.style.display = "none";
            closeBtnRef.current.style.display = "block";
        }
    }

    const toggleConversation = () => {
        if (conversationExpansionRef.current.style.display === "block") {
            // document.removeEventListener('click', clickOutsideHandle)
            conversationExpansionRef.current.classList.add("hide");
            setTimeout(() => {
                conversationExpansionRef.current.style.display = "none";
            }, 300);
        } else {
            document.addEventListener('click', clickOutsideHandle)
            conversationExpansionRef.current.classList.remove("hide");
            conversationExpansionRef.current.style.display = "block";
        }
    }

    const clickOutsideHandle = (event) => {
        if (!contactWrapperRef.current.contains(event.target) && btnExpansionRef.current.style.display === "block") {
            toggleContact();
            return;
        }
        if (!conversationWrapperRef.current.contains(event.target) && conversationExpansionRef.current.style.display === "block" && event.target.tagName !== "LI") {
            toggleConversation();
            return;
        }
        // document.removeEventListener('click', clickOutsideHandle)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!promptInput) return;
        setCurrentPrompt(promptInput);
        setPromptInput("");
    }

    const handleSelectQuestion = (selectedQuestion) => {
        setCurrentPrompt(selectedQuestion.question);
        setQuestions(prevQuestions => 
            prevQuestions.map(question => 
              question.id === selectedQuestion.id 
                ? { ...question, asked: true } 
                : question
            )
        );
    }

    function formatText(text) {
        // Replace lines starting with "##" with <h6> tags
        text = text.replace(/^##\s*(.+)/gm, '<h6>$1</h6>');
        
        // Replace lines starting with "#" with <h5> tags
        text = text.replace(/^#\s*(.+)/gm, '<h5>$1</h5>');
      
        // Replace double asterisks "**" with <strong> tags for bold text
        text = text.replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>');
        
        // Replace single asterisks "*" with <em> tags for italic text
        text = text.replace(/\*(.+?)\*/g, '<em>$1</em>');
        
        // Replace newlines with <br> tags
        text = text.replace(/\n/g, '<br>');
        // text = text.replace(/\n+/g, '<br>');

        text = text.replace(/\n\*/g, '\n•');
      
        return text;
    }

    const fetchAiResponse = async (prompt) => {
        try {
            const response = await apiService.aiAssistantChat(prompt, {
                responseType: 'stream'
            });
    
            setIsLoading(false);
            let fullText = '';
    
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
    
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
    
                const chunk = decoder.decode(value, { stream: true });
                const lines = chunk.split('\n');
                
                for (const line of lines) {
                    if (line.startsWith('data: ')) {
                        try {
                            const jsonData = JSON.parse(line.slice(6));
                            if (jsonData && jsonData.candidates && jsonData.candidates[0].content.parts[0].text) {
                                const newText = jsonData.candidates[0].content.parts[0].text;
                                fullText += newText;
                                setCurrentResponse(formatText(fullText));
                            } else if (jsonData === null) {
                                setIsAnswerComplete(true)
                            }
                        } catch (error) {
                            console.error('Error parsing JSON:', error);
                        }
                    }
                }
            }
    
    
        } catch (error) {
            console.error('Error in fetchAiResponse:', error);
            setCurrentResponse('Error: ' + error.message);
        }
    };

    useEffect(() => {
        if(currentPrompt) {
            setIsLoading(true);
            setIsAnswerComplete(false);
            fetchAiResponse(currentPrompt);
            setIsScrolledToBottom(true);
        }
    }, [currentPrompt])
  
    useEffect(() => {
        if (!isLoading && currentResponse && currentResponse !== previousResponseRef.current) {
            // Reset state for new response
            setDisplayedText('');
            setIsRenderComplete(false);
            previousResponseRef.current = currentResponse;
        
            let index = 0;
            
            const interval = setInterval(() => {
                if(isScrolledToBottom) {
                    responseElRef.current.scrollTop = responseElRef.current.scrollHeight;
                    handleAutoScroll();
                }
                if (index < currentResponse.length - 2) {
                    setDisplayedText(currentResponse.slice(0, index + 1));
                    index++;
                } else if (isAnswerComplete) {
                    clearInterval(interval);
                    setIsRenderComplete(true);
                    setIsScrolledToBottom(true);
                }
            }, 10); // Adjust speed as needed
        
            return () => clearInterval(interval);
        }
    }, [isLoading, currentResponse]);

    const handleAutoScroll = () => {
        const responseEl = responseElRef.current;

        const handleScroll = () => {
            const isAtBottom = Math.abs(responseEl.scrollHeight - responseEl.scrollTop - responseEl.clientHeight) < 1;
            if(isScrolledToBottom) {
                if(!isAtBottom) {
                    isScrolledToBottom = false;
                    setIsScrolledToBottom(false);
                }
            }
        };

        // Add scroll event listener
        responseEl.addEventListener('scroll', handleScroll);

        // Clean up
        return () => {
            responseEl.removeEventListener('scroll', handleScroll);
        };
    }

    return (
        <>
            <div id="side-popup-container">
                <div className="contact-btn-wrapper side-popup-item" ref={contactWrapperRef}>
                    <div className="btn-wrapper" onClick={toggleContact}>

                        <Carousel id='carouselExampleControls' ref={carouselRef}>

                            {contactCarouselItems.map((item) => (
                                <Carousel.Item interval={item.interval} key={item.id}>
                                    <div className="wrapper-item">
                                        <div className={`item-container d-block w-100 h-100 ${item.class ? item.class : null}`}>
                                            <FontAwesomeIcon icon={item.icon} />
                                            <p>{item.title ? item.title : ''}</p>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            ))}

                        </Carousel>

                        <div id="close-btn" ref={closeBtnRef}>
                            <FontAwesomeIcon icon={faX} />
                        </div>

                    </div>
                    <div className="btn-expansion" ref={btnExpansionRef}>
                        <ul className="expansion-list">
                            {expansionItems.map((item) => (
                                <li className="expansion-item" key={item.id}>
                                    <a href={item.href} rel="noreferrer" target={item.isTarget ? '_blank' : null} className="item-wrapper">
                                        <img src={item.img} alt={item.alt} />
                                        <p>{item.title}</p>
                                    </a>
                                </li>
                            ))}
                            <div className="triangle"></div>
                        </ul>
                    </div>
                </div>
                <div className='ai-assistant-wrapper side-popup-item' ref={conversationWrapperRef}>
                    <div className='btn-wrapper' onClick={toggleConversation}>
                        <img src={AiAssistantIcon} alt="AI assistant icon" />
                    </div>
                    <div className='conversation-expansion' ref={conversationExpansionRef}>
                        <div ref={responseElRef} className='response'>
                            {!currentPrompt ?
                                <div className='suggested-question'>
                                    <h6>Suggested questions:</h6>
                                    <ul className='question-list'>
                                        {questions
                                            .filter(item => !item.asked)
                                            .slice(0, 3)
                                            .map((item) => (
                                                <li key={item.id} onClick={() => handleSelectQuestion(item)}>
                                                    {item.question}
                                                    <FontAwesomeIcon icon={faChevronRight} />
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            :
                                <div className='ai-response d-flex flex-column'>
                                    <div className='prompt-text d-flex'>
                                        <img src={UserIcon} alt="user icon" />
                                        <p>{currentPrompt}</p>
                                    </div>
                                    <div className='response-text d-flex'>
                                        <img src={AiAssistantIcon} alt="AI assistant icon" />
                                        {isLoading
                                            ? <p className='loading-animation'></p>
                                            : <div
                                                className={`animated-response ${isRenderComplete ? 'complete' : ''}`}
                                                dangerouslySetInnerHTML={{ __html: displayedText }}
                                            />
                                        }
                                    </div>
                                    {(isAnswerComplete && isRenderComplete) &&
                                        <div className='suggested-question'>
                                            <ul className='question-list'>
                                                {questions
                                                        .filter(item => !item.asked)
                                                        .slice(0, 3)
                                                        .map((item) => (
                                                            <li key={item.id} onClick={() => handleSelectQuestion(item)}>
                                                                {item.question}
                                                                <FontAwesomeIcon icon={faChevronRight} />
                                                            </li>
                                                        ))
                                                    }
                                            </ul>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <form className='prompt d-flex gap-1' onSubmit={handleSubmit}>
                            <input type="text" value={promptInput} name="name" placeholder="Ask me something!" onChange={(e) => setPromptInput(e.target.value)}></input>
                            <button type='submit'>
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </button>
                        </form>
                        <div className="triangle"></div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default ContactPopup;
