import React from 'react';
const siteUrl = "https://locconnect.id.vn"
function MetaData({ title, description }) {
    return (
        <head>
            <title>{title}</title>
            <meta name="description" content={description} />
        
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="ie=edge" />

            <link rel="apple-touch-icon" href={`${siteUrl}/icon.png`} />
            <link rel="icon" href={`${siteUrl}/icon.png`} type="image/png" />
            <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />

            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="keywords" content="locconnect, developer, kieu trong loc profile, front-end developer" />
            <meta name="author" content="Kieu Trong Loc" />
            <meta name="robots" content="index, follow" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@kieutrong_loc" />
        
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={`${siteUrl}/about-me-photo.png`} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:url" content={siteUrl} />
            <meta property="og:type" content="website" />
            <script type="application/ld+json">
                {`
                "@context": "https://schema.org",
                "@type": "Article",
                "headline": "Locconnect",
                "image": {
                    "@type": "ImageObject",
                    "url": "${siteUrl}/about-me-photo.png",
                    "width": 800,
                }
                `}
            </script>
        </head>
    );
}

export default MetaData;