import React, { useState } from 'react';
import Header from '../components/Header';
import HomeBody from '../components/HomeBody';
import Footer from '../components/Footer';
import ScrollTop from '../components/HomeBody/ScrollTop';
import ContactBtn from '../components/ContactBtn';
import MetaData from '../components/Seo/MetaData';

function Home() {
  const metaDataObj = {
    title: "Locconnect | Trong Loc Profile - specialize in Web Developer",
    description: "A profile website about me. I am more than happy to see you here, don't hesitate to contact me for an opportunity of collaboration or anything else..."
  };

  const [inView, setInView] = useState(null)

  // Function to receive data from the child component
  const getInViewSectionHandle = (view) => {
    setInView(view)
  };

  return (
    <>
      <MetaData title={metaDataObj.title} description={metaDataObj.description}/>
      <Header sendInViewSectionData={inView}/>
      <HomeBody sendInViewSection={getInViewSectionHandle} />
      <ScrollTop />
      <ContactBtn />
      <Footer />
    </>
  );
}

export default Home;
