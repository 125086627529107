import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { pageFunction } from '../plugins/script';
import ScrollProgress from './Header/ScrollProgress';


function Header({sendInViewSectionData}) {
  const navList = [
    {id: 1, title: 'About', name: 'about', href: window.location.pathname === "/" ? '#about' : '/#about'},
    {id: 2, title: 'Skills', name: 'skills', href: window.location.pathname === "/" ? '#skills' : '/#sklls'},
    {id: 3, title: 'Projects', name: 'projects', href: window.location.pathname === "/" ? '#projects' : '/#project'},
    {id: 4, title: 'Contact', name: 'contact', href: window.location.pathname === "/" ? '#contact' : '/#contact'},
    // {id: 5, title: 'More', name: 'about', href: window.location.pathname === "/" ? '#more' : '/#more'}
  ]
  const currentHash = window.location.hash
  const [navSelected, setNavSelected] = useState(currentHash || 'about');
  const [isNavActive, setIsNavActive] = useState(false)

  const scrollHandle = (item) => {
    setTimeout(() => {      
      if (window.innerWidth < 768) {
        if (isNavActive === true) {
          setIsNavActive(false)
        }
        isNavActiveHandle()
      }
      pageFunction.handleScroll(item)
    }, 1);
  };

  const isNavActiveHandle = () => {
    setIsNavActive(!isNavActive);
  }

  useEffect(() => {
    if(currentHash && currentHash !== "about") {
      scrollHandle(currentHash.slice(1));
    }
  }, [])

  useEffect(() => {
    if(sendInViewSectionData !== navSelected) {
      setNavSelected(sendInViewSectionData)
    }
  }, [navSelected, sendInViewSectionData])

  return (
    <div id="page-header">
      <section id='page-header-container'>
        <div id='nav-home'>
          <Link to="/">
            <FontAwesomeIcon icon={faHouse} />
            <h2>Trong</h2><h2>Loc</h2>
          </Link>
        </div>
        <div id='navigation'>
          <div className={`nav-icon ${isNavActive ? 'open' : ''}`} onClick={isNavActiveHandle}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
          <ul className={isNavActive ? 'show-nav' : '' }>
            {navList.map((item) => (
              <li
                className={navSelected === item.name ? 'nav-selected' : ''}
                onClick={() => scrollHandle(item.name)}
                key={item.id}>
                <Link to={item.href}>{item.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      </section>
      {!isNavActive ? (<ScrollProgress/>) : ('') }
    </div>
  );
}

export default Header;
