import React, { useEffect, useState, useRef } from 'react';
import { apiService } from '../../api/apiService';
import CustomModal from '../resuable/modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { Spinner } from 'react-bootstrap';

function ContactMe() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [timeCount, setTimeCount] = useState(0);
  const timerRef = useRef(null);


  const loadingRef = useRef();
  
  const handleCloseModal = () => {
    setShowModal(false);
    clearInterval(timerRef.current); // Ensure timer is stopped when modal is closed
    setTimeCount(0);
  };
  const handleShowModal = () => setShowModal(true);


  useEffect(() => {
    if (alertMessage !== '') {
      alert(alertMessage);
    }
  }, [alertMessage]);

  const timerStart = () => {
    clearInterval(timerRef.current); // Clear any existing timer
    setTimeCount(0); // Reset the time count
    timerRef.current = setInterval(() => {
      setTimeCount(prev => {
        if (prev >= 30) {
          clearInterval(timerRef.current);
          return 30;
        }
        return prev + 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (timeCount === 30 && isLoading) {
      handleShowModal();
      setModalTitle('Time-out Error');
      setModalContent('Time-out. Please try again later!');
      loadingRef.current.style.display='none';
      setTimeCount(0)
      setIsLoading(false);
    }
  }, [timeCount, isLoading]);

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current); // Clean up the timer when component unmounts
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    timerStart();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setAlertMessage('Please enter valid email!')
    } else {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('message', message);
      loadingRef.current.style.display='block'
      setIsLoading(true)
      setAlertMessage('')
      const response = await apiService.submitForm(formData);
      if (response) {
        setIsLoading(false);
        setTimeCount(0);
        handleShowModal();
        if(response.data.msg === 'ok') {
          setName('');
          setEmail('');
          setPhone('');
          setMessage('');
          setModalTitle('Message Delivered');
          setModalContent("Thanks for leaving message. I'd check it out soon!")
          setTimeout(() => {
            loadingRef.current.style.display='none'
          }, 3000);
        } else if (response.data.msg === 'error') {
          setModalTitle('Sending Error');
          setModalContent('Unexpected Error. Please try again later!')
          loadingRef.current.style.display='none'
        }        
      }
    }
  }

  const alertMessageHandle = () => {
    setAlertMessage('');
  }

  const handleAnimationOnScroll = () => {
    const itemList = document.querySelectorAll('#contact-me-header, #user-contact input, #user-contact textarea, #submit-btn');
    const windowHeight = window.innerHeight;
    const threshold = 200;

    itemList.forEach(item => {
      const itemRect = item.getBoundingClientRect();

      if (itemRect.top + threshold < windowHeight) {
        item.classList.add('slide-in');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleAnimationOnScroll);
    handleAnimationOnScroll();

    return () => {
      window.removeEventListener('scroll', handleAnimationOnScroll);
    };
  }, []);

  return (
    <div id="contact-me">
      <section id="contact-me-container">
        <div id="contact-me-header">
          <span><p>Get in</p><p>Touch</p></span>
          <h1>Contact me</h1>
        </div>
        <div id="form" onSubmit={handleSubmit}>
          <form>
            <div id="user-input">
              <div id="user-contact">
                <input type="text" value={name} name="name" placeholder="Your Name*" onChange={(e) => {setName(e.target.value); alertMessageHandle()}} required></input>
                <input type="text" value={email} name="mail" placeholder="Your Email*" onChange={(e) => {setEmail(e.target.value); alertMessageHandle()}} required></input>
                <input type="text" value={phone} name="phone" placeholder="Your Phone" onChange={(e) => setPhone(e.target.value.replace(/[^0-9+\\-]/g, ''))}></input>
                <textarea name="message" value={message} id="message" cols="40" rows="10" placeholder="Your Message" onChange={(e) => setMessage(e.target.value)}></textarea>
              </div>

              <div id="submit-btn">

                <div className="submit-btn-container">
                  <div className="input-btn">
                    <svg>
                      <defs>
                        <linearGradient id="grad1">
                          <stop offset="0%" stopColor="#31CA56"/>
                          <stop offset="100%" stopColor="#BAF908" />
                        </linearGradient>
                      </defs>
                      <rect x="5" y="5" rx="25" fill="none" stroke="url(#grad1)" width="266" height="50" className={isLoading ? 'disable-animation' : ''}></rect>
                    </svg>
                    <div id='input-container'>
                      <input type="submit" value='SEND MESSAGE' disabled={isLoading ? true : false}></input>
                      <div id='loading' ref={loadingRef}>
                        {isLoading && <Spinner animation="border" variant="light" />}
                        {!isLoading && <FontAwesomeIcon icon={faCheck} />}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </form>
        </div>
      </section>
      <CustomModal 
        show={showModal} 
        onHide={handleCloseModal} 
        title={modalTitle} 
        content={modalContent} 
      />
    </div>
  );
}

export default ContactMe;