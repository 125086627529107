import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import UfoIcon from '../assets/img/ufo-icon.gif'
import MetaData from '../components/Seo/MetaData';

function NotFound() {
  const metaDataObj = {
    title: "Trong Loc Profile | Not Found - 404 Error",
    description: "We encounter an error, please check again!"
  };


  const handleAnimationOnScroll = () => {
    const itemList = document.querySelectorAll('#not-found .not-found-wrapper h1, #not-found .not-found-wrapper .text-wrapper, #not-found .not-found-wrapper a ');
    const windowHeight = window.innerHeight;
    const threshold = 200;
    itemList.forEach(item => {
      const itemRect = item.getBoundingClientRect();

      if (itemRect.top + threshold < windowHeight) {
        item.classList.add('slide-in');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleAnimationOnScroll);
    handleAnimationOnScroll();

    return () => {
      window.removeEventListener('scroll', handleAnimationOnScroll);
    };
  }, []);

  return (
    <div>
      <MetaData title={metaDataObj.title} description={metaDataObj.description}/>
      <Header/>

        <div id='not-found'>
          <div className='not-found-wrapper'>
            <div className='img'>
              <img src={UfoIcon} alt="ufo-not-found"/>
            </div>
            <h1>Oops!</h1>
            <div className='text-wrapper'>
              <h3>404 - page not found</h3>
              <p>Are you sure the URL is correct? This page can be found at nowhere. Otherwise, the page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
            </div>
            <Link to="/">go to homepage</Link>
          </div>
        </div>

      <Footer />
    </div>
  );
}

export default NotFound;
