import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5, faJs, faCss3, faVuejs, faReact, faBootstrap, faPhp, faNodeJs, faAws } from '@fortawesome/free-brands-svg-icons';
import ManCoding from '../../assets/img/my-skills/man-coding.gif';
import { faDatabase, faServer } from '@fortawesome/free-solid-svg-icons';
import resumePdf from '../../assets/files/resume.pdf';
import CustomModal from '../resuable/modal';

function MySkills() {
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');

  const maxLevel = 5;
  
  const skillsArray = [
    {
      category: 'Front-End',
      skills: [
        { id: 1, skill: 'HTML5', icon: faHtml5 , level: 4 },
        { id: 2, skill: 'Javascript', icon: faJs , level: 3 },
        { id: 3, skill: 'CSS', icon: faCss3 , level: 4 },
        { id: 4, skill: 'VueJS', icon: faVuejs , level: 4 },
        { id: 5, skill: 'ReactJS', icon: faReact , level: 3 },
        { id: 6, skill: 'Bootstrap', icon: faBootstrap , level: 2 },
      ],
    },
    {
      category: 'Back-End',
      skills: [
        { id: 1, skill: 'PHP', icon: faPhp , level: 4 },
        { id: 2, skill: 'NodeJS', icon: faNodeJs , level: 3 },
        { id: 3, skill: 'MySQL', icon: faDatabase , level: 3 },
      ],
    },
    {
      category: 'Others',
      skills: [
        { id: 1, skill: 'Cloud', icon: faServer , level: 2 },
        { id: 2, skill: 'AWS', icon: faAws , level: 2 },
      ],
    },
  ];
  
  const handleDownload = () => {
    setShowModal(true);
    setModalTitle("Sorry... Download disabled!");
    setModalContent("Downloading my resume has been disabled due to privacy matter. Still want it? Please contact me!");
    return;
    // but if you use some tricks, you deserve it lol. feel free to get it :D
    const link = document.createElement('a');
    link.download = 'Front-End-Resume-Kieu-Trong-Loc';
    link.href = resumePdf;
    link.click();
  };

  const handleAnimationOnScroll = () => {
    const itemList = document.querySelectorAll('#image-section #image, #image-section #download-button, #skill-header, .category-item');
    const windowHeight = window.innerHeight;
    const threshold = 200;

    itemList.forEach(item => {
      const itemRect = item.getBoundingClientRect();

      if (itemRect.top + threshold < windowHeight) {
        item.classList.add('slide-in');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleAnimationOnScroll);
    handleAnimationOnScroll();

    return () => {
      window.removeEventListener('scroll', handleAnimationOnScroll);
    };
  }, []);

  return (
    <div id="my-skills">
      <section id="my-skills-container">

        <div id="image-section">
          <div id='image'>
            <img src={ManCoding} alt="man coding" />
          </div>
          <div id='download-button'>
              <div id='button' onClick={handleDownload}>
                <p>Download Resume</p>
              </div>
              <div id='button-background'></div>
          </div>
        </div>

        <div id="skill-section">
          <div id="skill-header">
            <h1>I'm specialized in</h1>
            <h1>Front-End Development</h1>
          </div>

          <div id="skills-detail">

            <div className="category-list">
              {skillsArray.map((category) => (
                <div key={category.category} className='category-item'>

                  <h2>{category.category}</h2>
                  <div className='skill-list'>
                    {category.skills.map((skill) => (
                      <div key={skill.id} className='skill-item'>

                        <div className='skill-title'>
                          <FontAwesomeIcon icon={skill.icon} />
                          <p>{skill.skill}</p>
                        </div>

                        <div style={{ display: 'flex' }} className='skill-level'>
                          {Array.from({ length: maxLevel }).map((_, index) => (
                            <span
                              key={index}
                              style={{opacity: index < skill.level ? 1 : 0.3}}
                            ></span>
                          ))}
                        </div>

                      </div>
                    ))}
                  </div>

                </div>
              ))}
            </div>

          </div>

        </div>

      </section>
      <CustomModal 
        show={showModal} 
        onHide={() => setShowModal(false)} 
        title={modalTitle} 
        content={modalContent} 
      />
    </div>
  );
}

export default MySkills;