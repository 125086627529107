import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
export const apiService = {

    async submitForm(formData) {
        try {
            const response = await axios.post(apiUrl + '?folder=submit-mail', formData);
            return response;
        } catch (error) {
            console.log(error);
            throw error;
        }
    },

    async getLocation(locData) {
        try {
            const response = await axios.post(apiUrl + '?folder=get-location-data&loc-data=' + locData);
            return response;
        } catch (error) {
            console.log(error);
            throw error;
        }
    },

    async getWeather(locationKey) {
        try {
            const response = await axios.post(apiUrl + '?folder=get-weather-data&location-key=' + locationKey);
            return response;
        } catch (error) {
            console.log(error);
            throw error;
        }
    },

    async aiAssistantChat(prompt) {
        try {
            const response = await fetch(apiUrl + '?folder=ai-assistant', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `prompt=${encodeURIComponent(prompt)}`,
            });
    
            return response;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

};